export default [
  {
    path: '/sales',
    component: () =>
      import(
        /* webpackChunkName: "sales" */ '@/modules/sales/components/base/SalesLayout'
      ),
    meta: {
      auth: true,
      breadCrumb: 'Dashboard'
    },
    // beforeEnter: (to, from, next) => {
    //   apolloClient
    //     .query({
    //       query: APP_LIST
    //     })
    //     .then(result => {
    //       let app = _.find(result.data.apps, function (item) {
    //         return item.name === 'Sales'
    //       })

    //       store.commit('setCurrentApp', app)
    //       return next()
    //     })

    //   return next()
    // },
    children: [
      {
        path: '',
        name: 'SalesDashboard',
        component: () =>
          import(
            /* webpackChunkName: "sales" */ '@/modules/sales/views/Dashboard.vue'
          ),
        meta: {
          pageName: 'Dashboard',
          appName: 'Sales',
          auth: true
        },
        redirect: {
          name: 'SalesOrderList'
        }
      },
      {
        path: 'seasons',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Seasons'
        },
        children: [
          {
            path: '',
            name: 'SalesSeasonList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/SeasonList.vue'
              ),
            meta: {
              pageName: 'Seasons',
              appName: 'Sales',
              auth: true
            }
          },
          {
            path: ':seasonID',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/components/base/SeasonDetailLayout.vue'
              ),
            meta: {
              pageName: 'Seasons',
              appName: 'Sales',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'SalesSeasonDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionSeasonDetail.vue'
                  ),
                meta: {
                  pageName: 'Seasons',
                  appName: 'Sales',
                  auth: true
                }
              },
              {
                path: 'collection-overviews',
                name: 'SalesSeasonAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ColectionOverview.vue'
                  ),
                meta: {
                  pageName: 'Seasons',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Collection Overviews'
                }
              },
              {
                path: 'products',
                name: 'SalesSeasonProducts',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionSeasonProducts.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Products'
                }
              },
              {
                path: 'settings',
                name: 'SalesSeasonSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionSeasonSettings.vue'
                  ),
                meta: {
                  pageName: 'Settings',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'products',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          auth: true,
          breadCrumb: 'Products'
        },
        children: [
          {
            path: '',
            name: 'SalesProductList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/ProductList.vue'
              ),
            meta: {
              pageName: 'Products',
              appName: 'Sales',
              auth: true
            }
          },
          {
            path: ':productID',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/components/base/ProductDetailLayout.vue'
              ),
            meta: {
              pageName: 'Products',
              appName: 'Sales',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'SalesProductDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductDetail.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true
                }
              },
              {
                path: 'design-assets',
                name: 'SalesProductDesignAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductDesignAssets.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Tech Packs'
                }
              },
              {
                path: 'sales-assets',
                name: 'SalesProductProductionAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductProductionAssets.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Style Specs'
                }
              },
              {
                path: 'manufacturers',
                name: 'SalesProductManufacturers',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductManufacturers.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Manufacturers'
                }
              },
              {
                path: 'customs',
                name: 'SalesProductCustoms',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductCustoms.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Customs/Logistics'
                }
              },
              {
                path: 'materials',
                name: 'SalesProductMaterials',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductMaterials.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Materials'
                }
              },
              {
                path: 'costs',
                name: 'SalesProductCosts',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductCosts.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Costs'
                }
              },
              {
                path: 'subscribers',
                name: 'SalesProductCustomers',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductCustomers.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Subscribers'
                }
              },
              {
                path: 'pricing',
                name: 'SalesProductPricing',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductPricing.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Pricing'
                }
              },
              {
                path: 'seasons',
                name: 'SalesProductSeasons',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductSeasons.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Seasons'
                }
              },
              {
                path: 'images',
                name: 'SalesProductImages',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductImages.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Images'
                }
              },
              {
                path: 'inventory',
                name: 'SalesProductInventory',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductInventory.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Inventory'
                }
              },
              {
                path: 'audit',
                name: 'SalesProductAuditFlows',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductAuditFlows.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Audit'
                }
              },
              {
                path: 'change-log',
                name: 'SalesProductChangeLog',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductChangeLog.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Change Log'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'line-sheets',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          auth: true,
          breadCrumb: 'Line Sheets'
        },
        children: [
          {
            path: '',
            name: 'LineSheetList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/LineSheetList.vue'
              ),
            meta: {
              pageName: 'Line Sheets',
              appName: 'Sales',
              auth: true
            }
          },
          {
            path: ':lineSheetID',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/components/base/LineSheetDetailLayout.vue'
              ),
            meta: {
              pageName: 'Line Sheets',
              appName: 'Sales',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'LineSheetDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/LineSheetDetail.vue'
                  ),
                meta: {
                  pageName: 'Line Sheets',
                  appName: 'Sales',
                  auth: true
                }
              },
              {
                path: 'products',
                name: 'LineSheetProducts',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/LineSheetProducts.vue'
                  ),
                meta: {
                  pageName: 'Line Sheets',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Products'
                }
              },
              {
                path: 'subscribers',
                name: 'LineSheetSubscriberList',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/LineSheetSubscriberList.vue'
                  ),
                meta: {
                  pageName: 'Line Sheets',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Subscribers'
                }
              },
              {
                path: 'settings',
                name: 'LineSheetSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/LineSheetSettings.vue'
                  ),
                meta: {
                  pageName: 'Line Sheets',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'agents',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          auth: true,
          breadCrumb: 'Agents'
        },
        children: [
          {
            path: '',
            name: 'AgentList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/AgentList.vue'
              ),
            meta: {
              pageName: 'Agents',
              appName: 'Sales',
              auth: true
            }
          },
          {
            path: ':agentID',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/components/base/AgentDetailLayout.vue'
              ),
            meta: {
              pageName: 'Agents',
              appName: 'Sales',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'AgentDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/AgentDetail.vue'
                  ),
                meta: {
                  pageName: 'Agents',
                  appName: 'Sales',
                  auth: true
                }
              },
              {
                path: 'customers',
                name: 'AgentCustomerList',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/AgentCustomerList.vue'
                  ),
                meta: {
                  pageName: 'Customers',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Customers'
                }
              },
              {
                path: 'agreements',
                name: 'AgentAgreementList',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/AgentAgreementList.vue'
                  ),
                meta: {
                  pageName: 'Agreements',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Agreements'
                }
              },
              {
                path: 'orders',
                name: 'AgentOrders',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/SalesOrderListByAgent.vue'
                  ),
                meta: {
                  pageName: 'Orders',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Orders'
                }
              },
              {
                path: 'settings',
                name: 'AgentSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/AgentSettings.vue'
                  ),
                meta: {
                  pageName: 'Settings',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'customers',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          auth: true,
          breadCrumb: 'Customers'
        },
        children: [
          {
            path: '',
            name: 'CustomerList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/CustomerList.vue'
              ),
            meta: {
              pageName: 'Customers',
              appName: 'Sales',
              auth: true
            }
          },
          {
            path: ':customerID',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/components/base/CustomerDetailLayout.vue'
              ),
            meta: {
              pageName: 'Customers',
              appName: 'Sales',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'SalesCustomerDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/CustomerDetail.vue'
                  ),
                meta: {
                  pageName: 'Customers',
                  appName: 'Sales',
                  auth: true
                }
              },
              {
                path: 'agreements',
                name: 'SalesCustomerAgreements',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/CustomerAgreementList.vue'
                  ),
                meta: {
                  pageName: 'Agreements',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Agreements'
                }
              },
              {
                path: 'products',
                name: 'CustomerProductSubscriptionList',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/CustomerProductSubscriptionList.vue'
                  ),
                meta: {
                  pageName: 'Customers',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Customers'
                }
              },
              {
                path: 'orders',
                name: 'SalesCustomerOrders',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/SalesOrderListByCustomer.vue'
                  ),
                meta: {
                  pageName: 'Orders',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Orders'
                }
              },
              {
                path: 'settings',
                name: 'SalesCustomerSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/CustomerSettings.vue'
                  ),
                meta: {
                  pageName: 'Settings',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'sales-orders',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          auth: true,
          breadCrumb: 'Sales Orders'
        },
        children: [
          {
            path: '',
            name: 'SalesOrderList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/SalesOrderList.vue'
              ),
            meta: {
              pageName: 'Sales Orders',
              appName: 'Sales',
              auth: true
            }
          },
          {
            path: ':salesOrderID',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/components/base/SalesOrderDetailLayout.vue'
              ),
            meta: {
              pageName: 'Sales Orders',
              appName: 'Sales',
              auth: true,
              breadCrumb: 'Details'
            },
            children: [
              {
                path: '',
                name: 'SalesOrderDetails',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/SalesOrderDetail.vue'
                  ),
                meta: {
                  pageName: 'Details',
                  appName: 'Sales',
                  auth: true
                }
              },
              {
                path: 'products',
                name: 'SalesOrderProducts',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/CustomerProductShop.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Products'
                }
              },
              {
                path: 'cart',
                name: 'SalesOrderCart',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/CustomerProductCart.vue'
                  ),
                meta: {
                  pageName: 'Cart',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Cart'
                }
              },
              {
                path: 'summary',
                name: 'SalesOrderSummary',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/SalesOrderSummary.vue'
                  ),
                meta: {
                  pageName: 'Summary',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Summary'
                }
              },
              {
                path: 'allocation',
                name: 'SalesOrderAllocation',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/AllocationDetails.vue'
                  ),
                meta: {
                  pageName: 'Allocation',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Allocation'
                }
              },
              {
                path: 'pick-tickets',
                name: 'SalesOrderPickTickets',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/SalesOrderPickTickets.vue'
                  ),
                meta: {
                  pageName: 'Pick Tickets',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Pick Tickets'
                }
              },
              {
                path: 'delivery-notes',
                name: 'SalesOrderDeliveryNotes',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/sales/views/DeliveryNoteList.vue'
                  ),
                meta: {
                  pageName: 'Delivery Notes',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Delivery Notes'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'reports',
        component: () =>
          import(
            /* webpackChunkName: "sales" */ '@/modules/sales/components/base/SalesReportsLayout.vue'
          ),
        meta: {
          pageName: 'Reports',
          appName: 'Sales',
          auth: true,
          breadCrumb: 'Reports'
        },
        children: [
          {
            path: '',
            name: 'SalesReportList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/ReportList.vue'
              ),
            meta: {
              pageName: 'Reports',
              appName: 'Sales',
              auth: true
            }
          },
          {
            path: 'sales-orders-barcode',
            name: 'BarcodeSalesOrderReport',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/BarcodeSalesOrderReport.vue'
              ),
            meta: {
              pageName: 'Reports',
              appName: 'Sales',
              auth: true,
              breadCrumb: 'Sales Orders by Barcode'
            }
          },
          {
            path: 'sales-orders-customer',
            name: 'CustomerSalesOrderReport',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/CustomerSalesOrderReport.vue'
              ),
            meta: {
              pageName: 'Reports',
              appName: 'Sales',
              auth: true,
              breadCrumb: 'Sales Orders by Customer'
            }
          },
          {
            path: 'sales-orders-order-number',
            name: 'OrderNumberSalesOrderReport',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/OrderNumberSalesOrderReport.vue'
              ),
            meta: {
              pageName: 'Reports',
              appName: 'Sales',
              auth: true,
              breadCrumb: 'Sales Orders by Order Number'
            }
          },
          {
            path: 'stock-levels',
            name: 'StockLevelReport',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/logistics/views/ReportStockLevel.vue'
              ),
            meta: {
              pageName: 'Reports',
              appName: 'Sales',
              auth: true,
              breadCrumb: 'Stock Levels'
            }
          }
        ]
      },
      {
        path: 'warehouses',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Warehouses'
        },
        children: [
          {
            path: '',
            name: 'SalesWarehouseList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/WarehouseList.vue'
              ),
            meta: {
              pageName: 'Warehouses',
              appName: 'Sales',
              auth: true
            }
          },
          {
            path: ':warehouseID',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/components/base/WarehouseDetailLayout.vue'
              ),
            meta: {
              pageName: 'Warehouses',
              appName: 'Sales',
              auth: true,
              breadCrumb: 'Details'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'SalesWarehouseDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/WarehouseDetail.vue'
                  ),
                meta: {
                  pageName: 'Warehouses',
                  appName: 'Sales',
                  auth: true
                }
              },
              {
                path: 'stock',
                name: 'SalesWarehouseStockList',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/WarehouseStockList.vue'
                  ),
                meta: {
                  pageName: 'Warehouses',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Stock'
                }
              },
              {
                path: 'settings',
                name: 'SalesWarehouseSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/WarehouseSettings.vue'
                  ),
                meta: {
                  pageName: 'Warehouses',
                  appName: 'Sales',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'allocations',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Allocations'
        },
        children: [
          {
            path: '',
            name: 'SalesAllocationList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/AllocationList.vue'
              ),
            meta: {
              pageName: 'Allocations',
              appName: 'Sales',
              auth: true
            }
          },
          {
            path: ':allocationID',
            name: 'SalesAllocationDetails',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/logistics/views/AllocationDetails.vue'
              ),
            meta: {
              breadCrumb: 'Details',
              pageName: 'Details',
              appName: 'Sales',
              auth: true
            }
          }
        ]
      },
      {
        path: 'pick-tickets',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Pick Tickets'
        },
        children: [
          {
            path: '',
            name: 'SalesPickTicketList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/sales/views/PickTicketList.vue'
              ),
            meta: {
              pageName: 'Pick Tickets',
              appName: 'Sales',
              auth: true
            }
          },
          {
            path: ':pickTicketID',
            name: 'SalesPickTicketDetails',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/logistics/views/PickTicketDetails.vue'
              ),
            meta: {
              breadCrumb: 'Details',
              pageName: 'Details',
              appName: 'Sales',
              auth: true
            }
          }
        ]
      },
      {
        path: 'delivery-notes',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Delivery Notes'
        },
        children: [
          {
            path: '',
            name: 'SalesDeliveryNoteList',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/DeliveryNoteList.vue'
              ),
            meta: {
              pageName: 'Delivery Notes',
              appName: 'Sales',
              auth: true
            }
          },
          {
            path: ':deliveryNoteID',
            name: 'SalesDeliveryNoteDetails',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/DeliveryNoteDetails.vue'
              ),
            meta: {
              breadCrumb: 'Details',
              pageName: 'Details',
              appName: 'Sales',
              auth: true
            }
          }
        ]
      }
    ]
  }
]
