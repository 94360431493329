import Vue from 'vue'
import VueApollo from 'vue-apollo'
import {
  createApolloClient
  // restartWebsockets
} from 'vue-cli-plugin-apollo/graphql-client'
import { onError } from 'apollo-link-error'
// import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
// import { createHttpLink } from 'apollo-link-http'

import { createUploadLink } from 'apollo-upload-client'
import { RetryLink } from 'apollo-link-retry'
import router from '@/router'
import store from '@/store'

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'

// Http endpoint
const httpEndpoint =
  process.env.https://graphql.erppaper.com/query || 'http://localhost:5000/query'

// Retry Link
const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: error => !!error && error.message !== 'Failed to fetch' // Don't retry on network error
  }
})

var csrfToken = ''

const csrfLink = new ApolloLink((operation, forward) => {
  csrfToken = store.state.csrfToken

  operation.setContext({
    headers: {
      'X-CSRF-Token': csrfToken
    }
  })

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(
      ({ message }) => {
        if (message.includes('Unauthorized')) {
          if (process.env.NODE_ENV === 'development') {
            Vue.$cookies.remove('hpa')
          } else {
            Vue.$cookies.remove('hpa', null, 'erppaper.com')
          }

          apolloClient.resetStore()

          if (router.history.current.path !== '/login') {
            router
              .push('/login?redirect=' + router.history.current.path)
          }
        }

        if (message === 'Forbidden') {
          router.replace('/not-allowed').catch(() => {})
        }
        return false
      }
    )
  }

  if (networkError) {
    if (networkError.statusCode === 403 || networkError?.response?.status === 403 || networkError?.result?.status === 403) {
      store.dispatch('GET_CSRF_TOKEN')
    }
  }
})

const httpLink = createUploadLink({
  uri: httpEndpoint,
  credentials: 'include',

  fetchOptions: {
    credentials: 'include'
  }
})

const myLink = ApolloLink.from([retryLink, errorLink, csrfLink, httpLink])

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  // httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:5000/graphql',
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: myLink,
  defaultHttpLink: false // Enable the usage of termination links

  // fix the loading indicator on no-cache
  // notifyOnNetworkStatusChange: true

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Create apollo client for auth
export const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions
})

// Call this in the Vue app file
export function createProvider(options = {}) {
  apolloClient.options = options

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'network-only' // 'cache-and-network' || 'network-only' || 'no-cache' || 'cache-only' || 'cache-first'
      }
    },
    errorHandler(error) {
      if (error.message.includes('Unauthorized')) {
        if (process.env.NODE_ENV === 'development') {
          Vue.$cookies.remove('hpa')
          // document.cookie = 'hpa= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
        } else {
          Vue.$cookies.remove('hpa', null, 'erppaper.com')
          // document.cookie = 'hpa= ; expires = Thu, 01 Jan 1970 00:00:00 GMT ; domain = erppaper.com'
        }

        store.state.currentUser = null
      }
    }
  })

  return apolloProvider
}
