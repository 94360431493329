import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueMoment from 'vue-moment'
import VueEditor from 'vue2-editor'
import VueCurrencyInput from 'vue-currency-input'
import VCurrencyField from 'v-currency-field'
import Vue2Filters from 'vue2-filters'
import { VTextField } from 'vuetify/lib'
import JsonCSV from 'vue-json-csv'
import JsonExcel from 'vue-json-excel'
import VueMask from 'v-mask'
import CodeDiff from 'v-code-diff'

import { createProvider } from './vue-apollo'

// Global Moment config
const moment = require('moment')
// require('moment/locale/en-gb')
// require('moment/locale/nl')

Vue.component('v-text-field', VTextField)

Vue.use(VCurrencyField, {
  // locale: moment.locale(),
  decimalLength: 2,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: 0
})

Vue.use(VueCurrencyInput, {
  globalOptions: {
    // locale: moment.locale(),
    // currency: 'EUR',
    currency: null,
    precision: 2,
    // autoDecimalMode: false,
    // distractionFree: false,
    valueAsInteger: true,
    allowNegative: true
  }
})

// moment.locale('en-gb')

axios.defaults.baseURL = process.env.https://graphql.erppaper.com/
Vue.use(VueCookies)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueMoment, { moment })
Vue.use(VueEditor)
Vue.use(JsonCSV)
Vue.use(JsonExcel)
Vue.use(VueMask)
Vue.use(CodeDiff)

Vue.use(Vue2Filters)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),

  render: h => h(App)
}).$mount('#app')
