export default [
  {
    path: '/logistics',
    component: () =>
      import(
        /* webpackChunkName: "logistics" */ '@/modules/logistics/components/base/LogisticsLayout'
      ),
    meta: {
      appName: 'Logistics',
      auth: true
    },
    children: [
      {
        path: '',
        name: 'LogisticsDashboard',
        component: () =>
          import(
            /* webpackChunkName: "logistics" */ '@/modules/logistics/views/Dashboard.vue'
          ),
        meta: {
          pageName: 'Dashboard',
          appName: 'Logistics',
          auth: true
        },
        redirect: {
          name: 'LogisticsWarehouseList'
        }
      },
      {
        path: 'seasons',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Seasons'
        },
        children: [
          {
            path: '',
            name: 'LogisticsSeasonList',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/SeasonList.vue'
              ),
            meta: {
              pageName: 'Seasons',
              appName: 'Logistics',
              auth: true
            }
          },
          {
            path: ':seasonID',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/components/base/SeasonDetailLayout.vue'
              ),
            meta: {
              pageName: 'Seasons',
              appName: 'Logistics',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'LogisticsSeasonDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionSeasonDetail.vue'
                  ),
                meta: {
                  pageName: 'Seasons',
                  appName: 'Logistics',
                  auth: true
                }
              },
              {
                path: 'collection-overviews',
                name: 'LogisticsSeasonAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ColectionOverview.vue'
                  ),
                meta: {
                  pageName: 'Seasons',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Collection Overviews'
                }
              },
              {
                path: 'products',
                name: 'LogisticsSeasonProducts',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionSeasonProducts.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Products'
                }
              },
              {
                path: 'settings',
                name: 'LogisticsSeasonSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionSeasonSettings.vue'
                  ),
                meta: {
                  pageName: 'Settings',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'products',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          auth: true,
          breadCrumb: 'Products'
        },
        children: [
          {
            path: '',
            name: 'LogisticsProductList',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/ProductList.vue'
              ),
            meta: {
              pageName: 'Products',
              appName: 'Logistics',
              auth: true
            }
          },
          {
            path: ':productID',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/components/base/ProductDetailLayout.vue'
              ),
            meta: {
              pageName: 'Products',
              appName: 'Logistics',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'LogisticsProductDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductDetail.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true
                }
              },
              {
                path: 'design-assets',
                name: 'LogisticsProductDesignAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductDesignAssets.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Tech Packs'
                }
              },
              {
                path: 'logistics-assets',
                name: 'LogisticsProductProductionAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductProductionAssets.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Style Specs'
                }
              },
              {
                path: 'manufacturers',
                name: 'LogisticsProductManufacturers',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductManufacturers.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Manufacturers'
                }
              },
              {
                path: 'customs',
                name: 'LogisticsProductCustoms',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductCustoms.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Customs/Logistics'
                }
              },
              {
                path: 'materials',
                name: 'LogisticsProductMaterials',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductMaterials.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Materials'
                }
              },
              {
                path: 'costs',
                name: 'LogisticsProductCosts',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductCosts.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Costs'
                }
              },
              {
                path: 'pricing',
                name: 'LogisticsProductPricing',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductPricing.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Pricing'
                }
              },
              {
                path: 'seasons',
                name: 'LogisticsProductSeasons',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductSeasons.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Seasons'
                }
              },
              {
                path: 'images',
                name: 'LogisticsProductImages',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductImages.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Images'
                }
              },
              {
                path: 'inventory',
                name: 'LogisticsProductInventory',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductInventory.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Inventory'
                }
              },
              {
                path: 'audit',
                name: 'LogisticsProductAuditFlows',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ProductionProductAuditFlows.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Audit'
                }
              },
              {
                path: 'change-log',
                name: 'LogisticsProductChangeLog',
                component: () =>
                  import(
                    /* webpackChunkName: "sales" */ '@/modules/production/views/ProductionProductChangeLog.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Change Log'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'manufacturers',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Manufacturers'
        },
        children: [
          {
            path: '',
            name: 'LogisticsManufacturerList',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/ManufacturerList.vue'
              ),
            meta: {
              pageName: 'Manufacturers',
              appName: 'Logistics',
              auth: true
            }
          },
          {
            path: ':manufacturerID',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/components/base/ManufacturerDetailLayout.vue'
              ),
            meta: {
              pageName: 'Manufacturers',
              appName: 'Logistics',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'LogisticsManufacturerDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ManufacturerDetail.vue'
                  ),
                meta: {
                  pageName: 'Manufacturers',
                  appName: 'Logistics',
                  auth: true
                }
              },
              {
                path: 'locations',
                name: 'LogisticsManufacturerLocations',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ManufacturerLocations.vue'
                  ),
                meta: {
                  pageName: 'Manufacturers',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Locations'
                }
              },
              {
                path: 'orders',
                name: 'LogisticsManufacturerManufacturingOrderList',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ManufacturerManufacturingOrderList.vue'
                  ),
                meta: {
                  pageName: 'Manufacturers',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Orders'
                }
              },
              {
                path: 'products',
                name: 'LogisticsManufacturerProducts',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ManufacturerProducts.vue'
                  ),
                meta: {
                  pageName: 'Manufacturers',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Products'
                }
              },
              {
                path: 'settings',
                name: 'LogisticsManufacturerSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/ManufacturerSettings.vue'
                  ),
                meta: {
                  pageName: 'Manufacturers',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'manufacturing-orders',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Manufacturing Orders'
        },
        children: [
          {
            path: '',
            name: 'LogisticsManufacturingOrderList',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/ManufacturingOrderList.vue'
              ),
            meta: {
              pageName: 'Manufacturing Orders',
              appName: 'Logistics',
              auth: true,
              breadCrumb: 'Manufacturing'
            }
          },
          {
            path: ':manufacturingOrderID',
            name: 'LogisticsManufacturingOrderDetail',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/production/views/ManufacturingOrderDetail.vue'
              ),
            meta: {
              pageName: 'Manufacturing Orders',
              appName: 'Logistics',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true
          }
        ]
      },
      {
        path: 'warehouses',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Warehouses'
        },
        children: [
          {
            path: '',
            name: 'LogisticsWarehouseList',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/WarehouseList.vue'
              ),
            meta: {
              pageName: 'Warehouses',
              appName: 'Logistics',
              auth: true
            }
          },
          {
            path: ':warehouseID',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/components/base/WarehouseDetailLayout.vue'
              ),
            meta: {
              pageName: 'Warehouses',
              appName: 'Logistics',
              auth: true,
              breadCrumb: 'Details'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'LogisticsWarehouseDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/WarehouseDetail.vue'
                  ),
                meta: {
                  pageName: 'Warehouses',
                  appName: 'Logistics',
                  auth: true
                }
              },
              {
                path: 'stock',
                name: 'LogisticsWarehouseStockList',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/WarehouseStockList.vue'
                  ),
                meta: {
                  pageName: 'Warehouses',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Stock'
                }
              },
              {
                path: 'settings',
                name: 'LogisticsWarehouseSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/WarehouseSettings.vue'
                  ),
                meta: {
                  pageName: 'Warehouses',
                  appName: 'Logistics',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'stock',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          pageName: 'Stock',
          appName: 'Logistics',
          auth: true,
          breadCrumb: 'Stock'
        },
        children: [
          {
            path: '',
            name: 'LogisticsStockList',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/StockList.vue'
              ),
            meta: {
              pageName: 'Stock',
              appName: 'Logistics',
              auth: true
            }
          },

          {
            path: ':styleID',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/production/components/base/StockDetailLayout.vue'
              ),
            meta: {
              pageName: 'Stock',
              appName: 'Logistics',
              auth: true,
              breadCrumb: 'Details'
            },
            children: [
              {
                path: ':productSizeID',
                name: 'LogisticsStockItemDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "logistics" */ '@/modules/production/views/StockItemDetail.vue'
                  ),
                meta: {
                  pageName: 'Stock',
                  appName: 'Production',
                  auth: true
                }
              }
            ]
          }
        ]
      },
      {
        path: 'allocations',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Allocations'
        },
        children: [
          {
            path: '',
            name: 'LogisticsAllocationList',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/AllocationList.vue'
              ),
            meta: {
              pageName: 'Allocations',
              appName: 'Logistics',
              auth: true
            }
          },
          {
            path: ':allocationID',
            name: 'LogisticsAllocationDetails',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/AllocationDetails.vue'
              ),
            meta: {
              breadCrumb: 'Details',
              pageName: 'Details',
              appName: 'Logistics',
              auth: true
            }
          }
        ]
      },
      {
        path: 'advance-shipping-notifications',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Advance Shipping Notifications'
        },
        children: [
          {
            path: '',
            name: 'AdvanceShippingNotificationList',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/AdvanceShippingNotificationList.vue'
              ),
            meta: {
              pageName: 'Advance Shipping Notifications',
              appName: 'Logistics',
              auth: true
            }
          },
          {
            path: ':asnID',
            name: 'AdvanceShippingNotificationDetails',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/AdvanceShippingNotificationDetails.vue'
              ),
            meta: {
              breadCrumb: 'Details',
              pageName: 'Details',
              appName: 'Logistics',
              auth: true
            }
          }
        ]
      },
      {
        path: 'pick-tickets',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Pick Tickets'
        },
        children: [
          {
            path: '',
            name: 'LogisticsPickTicketList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/logistics/views/PickTicketList.vue'
              ),
            meta: {
              pageName: 'Pick Tickets',
              appName: 'Logistics',
              auth: true
            }
          },
          {
            path: ':pickTicketID',
            name: 'LogisticsPickTicketDetails',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/logistics/views/PickTicketDetails.vue'
              ),
            meta: {
              breadCrumb: 'Details',
              pageName: 'Details',
              appName: 'Logistics',
              auth: true
            }
          }
        ]
      },
      {
        path: 'delivery-notes',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Delivery Notes'
        },
        children: [
          {
            path: '',
            name: 'LogisticsDeliveryNoteList',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/logistics/views/DeliveryNoteList.vue'
              ),
            meta: {
              pageName: 'Delivery Notes',
              appName: 'Logistics',
              auth: true
            }
          },
          {
            path: ':deliveryNoteID',
            name: 'LogisticsDeliveryNoteDetails',
            component: () =>
              import(
                /* webpackChunkName: "sales" */ '@/modules/logistics/views/DeliveryNoteDetails.vue'
              ),
            meta: {
              breadCrumb: 'Details',
              pageName: 'Details',
              appName: 'Logistics',
              auth: true
            }
          }
        ]
      },
      {
        path: 'settings',
        component: () =>
          import(
            /* webpackChunkName: "logistics" */ '@/modules/logistics/components/base/SettingsLayout.vue'
          ),
        meta: {
          pageName: 'Settings',
          appName: 'Logistics',
          auth: true,
          breadCrumb: 'Settings'
        },
        redirect: { name: 'SettingsErrorList' },
        children: [
          {
            path: 'warehouse-providers',
            name: 'LogisticsSettingsWarehouseProviders',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/SettingsWarehouseProviders.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Logistics',
              auth: true,
              breadCrumb: 'Warehouse Providers'
            }
          },
          {
            path: '',
            name: 'SettingsErrorList',
            component: () =>
              import(
                /* webpackChunkName: "logistics" */ '@/modules/logistics/views/SettingsErrorList.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Logistics',
              auth: true,
              breadCrumb: 'Errors'
            }
          }
        ]
      }
    ]
  }
]
