export default [
  {
    path: '/production',
    component: () =>
      import(
        /* webpackChunkName: "production" */ '@/modules/production/components/base/ProductionLayout'
      ),
    meta: {
      auth: true,
      breadCrumb: 'Dashboard'
    },
    // beforeEnter: (to, from, next) => {
    //   apolloClient
    //     .query({
    //       query: APP_LIST
    //     })
    //     .then(result => {
    //       let app = _.find(result.data.apps, function (item) {
    //         return item.name === 'Production'
    //       })

    //       store.commit('setCurrentApp', app)
    //       return next()
    //     })

    //   return next()
    // },
    children: [
      {
        path: '',
        name: 'ProductionDashboard',
        component: () =>
          import(
            /* webpackChunkName: "production" */ '@/modules/production/views/Dashboard.vue'
          ),
        meta: {
          pageName: 'Dashboard',
          appName: 'Production',
          auth: true
        },
        redirect: {
          name: 'ProductionProductList'
        }
      },
      {
        path: 'seasons',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Seasons'
        },
        children: [
          {
            path: '',
            name: 'ProductionSeasonList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSeasonList.vue'
              ),
            meta: {
              pageName: 'Seasons',
              appName: 'Production',
              auth: true
            }
          },
          {
            path: ':seasonID',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/components/base/ProductionSeasonDetailLayout.vue'
              ),
            meta: {
              pageName: 'Seasons',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'ProductionSeasonDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSeasonDetail.vue'
                  ),
                meta: {
                  pageName: 'Seasons',
                  appName: 'Production',
                  auth: true
                }
              },
              {
                path: 'collection-overviews',
                name: 'ProductionSeasonAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ColectionOverview.vue'
                  ),
                meta: {
                  pageName: 'Seasons',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Collection Overviews'
                }
              },
              {
                path: 'products',
                name: 'ProductionSeasonProducts',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSeasonProducts.vue'
                  ),
                meta: {
                  pageName: 'Seasons',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Products'
                }
              },
              {
                path: 'calendar',
                name: 'ProductionSeasonCalendar',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSeasonCalendar.vue'
                  ),
                meta: {
                  pageName: 'Calendar',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Detail'
                }
              },
              {
                path: 'planning',
                name: 'ProductionSeasonPlanning',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSeasonPlanning.vue'
                  ),
                meta: {
                  pageName: 'Seasons',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Planning'
                }
              },
              {
                path: 'settings',
                name: 'ProductionSeasonSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSeasonSettings.vue'
                  ),
                meta: {
                  pageName: 'Settings',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },

      {
        path: 'warehouses',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Warehouses'
        },
        children: [
          {
            path: '',
            name: 'ProductionWarehouseList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionWarehouseList.vue'
              ),
            meta: {
              pageName: 'Warehouses',
              appName: 'Production',
              auth: true
              // breadCrumb: 'Shippers'
            }
          },
          {
            path: ':warehouseID',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/components/base/WarehouseDetailLayout.vue'
              ),
            meta: {
              pageName: 'Warehouses',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Details'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'WarehouseDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/WarehouseDetail.vue'
                  ),
                meta: {
                  pageName: 'Warehouses',
                  appName: 'Production',
                  auth: true
                }
              },
              {
                path: 'stock',
                name: 'WarehouseStockList',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/WarehouseStockList.vue'
                  ),
                meta: {
                  pageName: 'Warehouses',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Stock'
                }
              },
              {
                path: 'settings',
                name: 'ProductionWarehouseSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/WarehouseSettings.vue'
                  ),
                meta: {
                  pageName: 'Warehouses',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'shippers',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Shippers'
        },
        children: [
          {
            path: '',
            name: 'ProductionShipperList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionShipperList.vue'
              ),
            meta: {
              pageName: 'Shippers',
              appName: 'Production',
              auth: true
              // breadCrumb: 'Shippers'
            }
          },
          {
            path: ':shipperID',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/components/base/ShipperDetailLayout.vue'
              ),
            meta: {
              pageName: 'Shippers',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Details'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'ShipperDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ShipperDetail.vue'
                  ),
                meta: {
                  pageName: 'Shippers',
                  appName: 'Production',
                  auth: true
                }
              }
            ]
          }
        ]
      },
      {
        path: 'stock',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          pageName: 'Stock',
          appName: 'Production',
          auth: true,
          breadCrumb: 'Stock'
        },
        children: [
          {
            path: '',
            name: 'ProductionStockList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionStockList.vue'
              ),
            meta: {
              pageName: 'Stock',
              appName: 'Production',
              auth: true
            }
          },

          {
            path: ':styleID',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/components/base/StockDetailLayout.vue'
              ),
            meta: {
              pageName: 'Stock',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Details'
            },
            children: [
              {
                path: ':productSizeID',
                name: 'StockItemDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/StockItemDetail.vue'
                  ),
                meta: {
                  pageName: 'Stock',
                  appName: 'Production',
                  auth: true
                }
              }
            ]
          }
        ]
      },

      {
        path: 'materials',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Materials'
        },
        children: [
          {
            path: '',
            name: 'ProductionMaterialList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionMaterialList.vue'
              ),
            meta: {
              pageName: 'Materials',
              appName: 'Production',
              auth: true
            }
          }
        ]
      },
      {
        path: 'products',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          auth: true,
          breadCrumb: 'Products'
        },
        children: [
          {
            path: '',
            name: 'ProductionProductList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductList.vue'
              ),
            meta: {
              pageName: 'Products',
              appName: 'Production',
              auth: true
            }
          },
          {
            path: ':productID',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/components/base/ProductDetailLayout.vue'
              ),
            meta: {
              pageName: 'Products',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'ProductionProductDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductDetail.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true
                }
              },
              {
                path: 'design-assets',
                name: 'ProductionProductDesignAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductDesignAssets.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Tech Packs'
                }
              },
              {
                path: 'production-assets',
                name: 'ProductionProductProductionAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductProductionAssets.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Style Specs'
                }
              },
              {
                path: 'manufacturers',
                name: 'ProductionProductManufacturers',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductManufacturers.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Manufacturers'
                }
              },
              {
                path: 'customs',
                name: 'ProductionProductCustoms',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductCustoms.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Customs/Logistics'
                }
              },
              {
                path: 'materials',
                name: 'ProductionProductMaterials',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductMaterials.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Materials'
                }
              },
              {
                path: 'costs',
                name: 'ProductionProductCosts',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductCosts.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Costs'
                }
              },
              {
                path: 'pricing',
                name: 'ProductionProductPricing',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductPricing.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Pricing'
                }
              },
              {
                path: 'seasons',
                name: 'ProductionProductSeasons',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductSeasons.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Seasons'
                }
              },
              {
                path: 'images',
                name: 'ProductionProductImages',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductImages.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Images'
                }
              },
              {
                path: 'inventory',
                name: 'ProductionProductInventory',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductInventory.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Inventory'
                }
              },
              {
                path: 'audit',
                name: 'ProductionProductAuditFlows',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductAuditFlows.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Audit'
                }
              },
              {
                path: 'change-log',
                name: 'ProductionProductChangeLog',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionProductChangeLog.vue'
                  ),
                meta: {
                  pageName: 'Products',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Change Log'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'manufactures',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Manufacturers'
        },
        children: [
          {
            path: '',
            name: 'ProductionManufacturerList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionManufacturerList.vue'
              ),
            meta: {
              pageName: 'Manufacturers',
              appName: 'Production',
              auth: true
            }
          },
          {
            path: ':manufacturerID',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/components/base/ManufacturerDetailLayout.vue'
              ),
            meta: {
              pageName: 'Manufacturers',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true,
            children: [
              {
                path: '',
                name: 'ManufacturerDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ManufacturerDetail.vue'
                  ),
                meta: {
                  pageName: 'Manufacturers',
                  appName: 'Production',
                  auth: true
                }
              },
              {
                path: 'locations',
                name: 'ManufacturerLocations',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ManufacturerLocations.vue'
                  ),
                meta: {
                  pageName: 'Manufacturers',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Locations'
                }
              },
              {
                path: 'orders',
                name: 'ManufacturerManufacturingOrderList',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ManufacturerManufacturingOrderList.vue'
                  ),
                meta: {
                  pageName: 'Manufacturers',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Orders'
                }
              },
              {
                path: 'products',
                name: 'ManufacturerProducts',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ManufacturerProducts.vue'
                  ),
                meta: {
                  pageName: 'Manufacturers',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Products'
                }
              },
              {
                path: 'settings',
                name: 'ManufacturerSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ManufacturerSettings.vue'
                  ),
                meta: {
                  pageName: 'Manufacturers',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'manufacturing-orders',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Manufacturing Orders'
        },
        children: [
          {
            path: '',
            name: 'ProductionManufacturingOrderList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionManufacturingOrderList.vue'
              ),
            meta: {
              pageName: 'Manufacturing Orders',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Manufacturing'
            }
          },
          {
            path: ':manufacturingOrderID',
            name: 'ManufacturingOrderDetail',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ManufacturingOrderDetail.vue'
              ),
            // component: () =>
            //   import(
            //     /* webpackChunkName: "production" */ '@/modules/production/components/base/ManufacturingOrderDetailLayout.vue'
            //   ),
            meta: {
              pageName: 'Manufacturing Orders',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Detail'
            },
            props: true
            // children: [
            //   {
            //     path: '',
            //     name: 'ManufacturingOrderDetail',
            //     component: () =>
            //       import(
            //         /* webpackChunkName: "production" */ '@/modules/production/views/ManufacturingOrderDetail.vue'
            //       ),
            //     meta: {
            //       pageName: 'Manufacturing Orders',
            //       appName: 'Production',
            //       auth: true
            //     }
            //   }
            // ]
          }
        ]
      },
      {
        path: 'process',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Workflows'
        },
        children: [
          {
            path: ':name',
            name: 'ProcessBoard',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProcessBoard.vue'
              ),
            meta: {
              pageName: 'Process',
              appName: 'Production',
              auth: true
            }
          }
        ]
      },
      {
        path: 'sales',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Sales'
        },
        redirect: {
          name: 'ProductionCustomerList'
        },
        children: [
          {
            path: 'customers',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            meta: {
              breadCrumb: 'Customers'
            },
            children: [
              {
                path: '',
                name: 'ProductionCustomerList',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/views/ProductionCustomerList.vue'
                  ),
                meta: {
                  pageName: 'Customers',
                  appName: 'Production',
                  auth: true
                  // breadCrumb: 'Customers'
                }
              },
              {
                path: ':customerID',
                component: () =>
                  import(
                    /* webpackChunkName: "production" */ '@/modules/production/components/base/CustomerDetailLayout.vue'
                  ),
                meta: {
                  pageName: 'Customers',
                  appName: 'Production',
                  auth: true,
                  breadCrumb: 'Details'
                },
                props: true,
                children: [
                  {
                    path: '',
                    name: 'CustomerDetail',
                    component: () =>
                      import(
                        /* webpackChunkName: "production" */ '@/modules/production/views/CustomerDetail.vue'
                      ),
                    meta: {
                      pageName: 'Customers',
                      appName: 'Production',
                      auth: true
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'orders',
            name: 'ProductionSalesOrderList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSalesOrderList.vue'
              ),
            meta: {
              pageName: 'Sales Orders',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Orders'
            }
          },
          {
            path: 'samples',
            name: 'ProductionSampleOrderList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSampleOrderList.vue'
              ),
            meta: {
              pageName: 'Sample Orders',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Samples'
            }
          }
        ]
      },
      {
        path: 'reports',
        component: () =>
          import(
            /* webpackChunkName: "production" */ '@/modules/production/components/base/ProductionReportsLayout.vue'
          ),
        meta: {
          pageName: 'Reports',
          appName: 'Production',
          auth: true,
          breadCrumb: 'Reports'
        },
        // redirect: { name: 'ProductionReportList' },
        children: [
          {
            path: '',
            name: 'ProductionReportList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionReportList.vue'
              ),
            meta: {
              pageName: 'Reports',
              appName: 'Production',
              auth: true
            }
          },
          {
            path: 'product-list',
            name: 'ProductionReportProductList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionReportProductList.vue'
              ),
            meta: {
              pageName: 'Reports',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Product List'
            }
          },
          {
            path: 'style-list',
            name: 'ProductionReportStyleList',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionReportStyleList.vue'
              ),
            meta: {
              pageName: 'Reports',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Style List'
            }
          }
        ]
      },
      {
        path: 'settings',
        component: () =>
          import(
            /* webpackChunkName: "production" */ '@/modules/production/components/base/ProductionSettingsLayout.vue'
          ),
        meta: {
          pageName: 'Settings',
          appName: 'Production',
          auth: true,
          breadCrumb: 'Settings'
        },
        redirect: { name: 'ProductionSettingsSizes' },
        children: [
          {
            path: 'workflow',
            name: 'ProductionSettingsWorkflow',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSettingsWorkflow.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Workflow'
            }
          },
          {
            path: 'colors',
            name: 'ProductionSettingsColors',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSettingsColors.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Colors'
            }
          },
          {
            path: 'categories',
            name: 'ProductionSettingsCategories',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSettingsCategories.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Categories'
            }
          },
          {
            path: 'article-groups',
            name: 'ProductionSettingsArticleGroups',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSettingsArticleGroups.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Article Groups'
            }
          },
          {
            path: 'sub-article-groups',
            name: 'ProductionSettingsSubArticleGroups',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSettingsSubArticleGroups.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Sub Article Groups'
            }
          },
          {
            path: 'style-types',
            name: 'ProductionSettingsStyleTypes',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSettingsStyleTypes.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Style Types'
            }
          },
          {
            path: 'sizes',
            name: 'ProductionSettingsSizes',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSettingsSizes.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Sizes'
            }
          },
          {
            path: 'barcode-ranges',
            name: 'ProductionSettingsBarcodes',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSettingsBarcodes.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Barcode Ranges'
            }
          },
          {
            path: 'markups-wholesale',
            name: 'ProductionSettingsWholesalePriceMarkup',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSettingsWholesalePriceMarkup.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Wholesale'
            }
          },
          {
            path: 'markups-retail',
            name: 'ProductionSettingsRetailPriceMarkup',
            component: () =>
              import(
                /* webpackChunkName: "production" */ '@/modules/production/views/ProductionSettingsRetailPriceMarkup.vue'
              ),
            meta: {
              pageName: 'Settings',
              appName: 'Production',
              auth: true,
              breadCrumb: 'Retail'
            }
          }
        ]
      }
    ]
  }
]
