<template>
  <v-container fill-height>
    <v-layout column>
      <v-row align="center" justify="center">
        <v-col v-if="status" xs="12" sm="12" md="5" lg="4" xl="3">
          <v-row class="mb-6" justify="center">
            <v-img src="/logo.png" contain max-width="200"></v-img>
          </v-row>
          <v-table-overflow>
            <v-col cols="12">
              <h1 class="title mb-3 text-center">{{ validationHeaderMsg }}</h1>
              <h4 class="subtitle-1 text-center">{{ validationMsg }}</h4>
            </v-col>
          </v-table-overflow>

          <v-row v-if="status === 'completed'" class="mt-3">
            <v-row justify="center">
              <v-btn color="primary" :to="{ name: 'login' }" large>Login</v-btn>
            </v-row>
          </v-row>
          <v-row v-if="setPasswordError" class="mt-3" no-gutters>
            <v-col>
              <v-alert type="error">{{ setPasswordErrorMsg }}</v-alert>
            </v-col>
          </v-row>

          <!-- <v-row v-if="status === 'invalid'">
            <v-col cols="12">
              <v-card outlined class="mx-auto">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Email"
                        type="email"
                        placeholder="Email used to signup"
                        outlined
                        hide-details
                        autofocus
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-btn color="primary" large block
                        >Resend Validation Email</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row> -->

          <v-row v-if="status === 'valid'">
            <v-col cols="12">
              <v-card outlined class="mx-auto">
                <v-card-text>
                  <v-form
                    ref="setPasswordForm"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="setPassword"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="user.email"
                          label="Email"
                          type="email"
                          placeholder="Email used to signup"
                          outlined
                          hide-details
                          autofocus
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="user.password"
                          label="Password"
                          type="password"
                          placeholder="Set a password to secure your account"
                          hint="Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character (@$!%*#?)"
                          persistent-hint
                          outlined
                          hide-details
                          :rules="passwordRule"
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-btn type="submit" color="primary" large block>
                          <v-icon left>mdi-lock-outline</v-icon>Set Password
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-5">
            <p>
              Feeling lost? Go
              <router-link text to="/">home</router-link>
            </p>
          </v-row>
        </v-col>
      </v-row>

      <!-- Footer -->
      <v-layout align-end row>
        <v-flex xs12>
          <v-footer height="auto" color="transparent">
            <v-layout align-center justify-center column>
              <v-flex text-xs-center xs12>
                <a href="https://www.clebbr.com/privacy" class="copy-link"
                  >Privacy Policy&nbsp;</a
                >
                |
                <a href="https://www.clebbr.com" class="copy-link"
                  >&nbsp;&copy; Clebbr Ltd</a
                >
              </v-flex>
            </v-layout>
          </v-footer>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
import SET_NEW_PASSWORD from '@/graphql/PasswordResetChange.gql'
import VALIDATE_PASSWORD_RESET from '@/graphql/PasswordResetValidate.gql'

export default {
  name: 'ResetPassword',
  data() {
    return {
      status: null,
      setPasswordError: false,
      setPasswordErrorMsg: 'The details provided are wrong! Please try again.',
      validationHeaderMsg: 'Welcome to ASA',
      validationMsg:
        "Your're almost there! Please set a password to get started.",

      user: {
        token: null,
        email: '',
        password: null
      },
      show: false,
      valid: true,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => 'The password is required'
      },
      passwordRule: [
        v => !!v || 'Password is required',
        v =>
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
            v
          ) ||
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character (@$!%*#?&)'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      errorMsg: 'Invalid username or password',
      showError: false,
      showVerrifyEmailAlert: false
    }
  },

  created() {
    // Validate the token on the server
    var data = {
      token: this.$route.params.token
    }

    this.$apollo
      .mutate({
        mutation: VALIDATE_PASSWORD_RESET,
        // Parameters
        variables: {
          input: data
        }
      })
      .then(res => {
        if (res.data.validateResetPassword.validated) {
          // Token valid
          this.status = 'valid'
          this.validationHeaderMsg = 'Welcome'
          this.validationMsg = 'Please set a new password to get back in.'
        } else {
          // Token not valid
          this.status = 'invalid'
          this.validationHeaderMsg = 'Token not valid'
          this.validationMsg =
            'The token you provided is not found. Please contact your administrator to request a new password reset token.'
        }
      })
      .catch(() => {
        this.status = 'invalid'
        this.validationHeaderMsg = 'Token not valid'
        this.validationMsg =
          'The token you provided is not found. Please contact your administrator to request a new password reset token.'
      })
  },

  methods: {
    setPassword() {
      this.setPasswordError = false
      if (this.$refs.setPasswordForm.validate()) {
        this.user.token = this.$route.params.token

        this.$apollo
          .mutate({
            mutation: SET_NEW_PASSWORD,
            // Parameters
            variables: {
              input: this.user
            }
          })
          .then(res => {
            if (res.data.changeResetPassword.validated) {
              // Completed registration
              this.status = 'completed'
              this.validationHeaderMsg = 'Password Changed'
              this.validationMsg =
                'Your password has been successfully changed.'
            } else {
              this.setPasswordError = true
            }
          })
      }
    }
  }
}
</script>

<style lang="scss">
.login__container {
  height: 100%;
}

.login-box {
  width: 400px;
  border-radius: 5px;
}

.v-input__slot {
  // border-color: var(--v-primary-lighten3);
  border-radius: 5px !important;
}

.copy-link {
  text-decoration: none;
  color: var(--v-secondary-base) !important;
  font-size: small;

  &:hover {
    text-decoration: underline;
    // color: var(--v-info-darken1);
  }
}
</style>
