export default [
  {
    path: '/accounts',
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ '@/modules/accounts/components/base/AccountsLayout'
      ),
    meta: {
      appName: 'Accounts',
      auth: true
    },
    children: [
      {
        path: '',
        name: 'MyAccount',
        component: () =>
          import(
            /* webpackChunkName: "accounts" */ '@/modules/accounts/views/MyAccount.vue'
          ),
        meta: {
          pageName: 'MyAccount',
          appName: 'Accounts',
          auth: true
        }
      }
    ]
  }
]
