import '@mdi/font/css/materialdesignicons.css'
import '@mdi/js'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import '../scss/main.scss'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        // primary: '#336699', // '#5e72e4',
        // secondary: '#172b4d',
        // accent: '#999', // '#f4f5f7'
        // error: '#f5365c',
        // info: '#11cdef',
        // success: '#2dce89',
        // warning: '#fb6340',
        'light-theme': '#f4f5f7' //'#f6f9fc'
      }
      // production: {
      //   primary: '#3f51b5',
      //   secondary: '#607d8b',
      //   accent: '#e91e63',
      //   error: '#f44336',
      //   warning: '#ffc107',
      //   info: '#03a9f4',
      //   success: '#4caf50'
      // }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})
