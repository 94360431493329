import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import _ from 'lodash'
import createPersistedState from 'vuex-persistedstate'
import production from './production'
import sales from './sales'
import logistics from './logistics'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: null,
    userApps: [],
    currentApp: null,
    selectedSeason: null,
    csrfToken: '',
    drawer: true,
    snackbar: {
      active: false,
      timeout: 3000,
      message: 'Changes saved',
      absolute: true,
      top: true,
      right: false,
      bottom: false,
      left: false,
      dismissText: 'close'
    }
  },
  mutations: {
    setCurrentApp(state, app) {
      state.currentApp = app
    },
    setSelectedSeason(state, season) {
      state.selectedSeason = season
    },
    hideDrawer(state) {
      state.drawer = false
    },
    toggleDrawer(state) {
      state.drawer ? (state.drawer = false) : (state.drawer = true)
    },
    setDrawerPosition(state, open) {
      open ? (state.drawer = true) : (state.drawer = false)
    },
    hideSnackbar(state) {
      state.snackbar = {
        active: false,
        timeout: 2000,
        message: 'Changes saved',
        absolute: false,
        top: true,
        right: false,
        bottom: false,
        left: false,
        dismissText: 'close'
      }
    },

    showSnackbar(state, snackbar) {
      let oldObj = _.cloneDeep(state.snackbar)
      let newObj = _.assign(oldObj, snackbar)
      state.snackbar = newObj
    },

    SET_USER(state, user) {
      state.currentUser = user
    },

    SET_USER_APPS(state, apps) {
      state.userApps = apps
    },

    SET_CSRF_TOKEN(state, token) {
      state.csrfToken = token
    },

    REMOVE_CSRF_TOKEN(state) {
      state.csrfToken = null
    }
  },
  actions: {
    async GET_CSRF_TOKEN({ commit }) {
      try {
        let response = await axios
          .create({ withCredentials: true })
          .get('csrftok', {
            // headers: { 'Cache-Control': 'no-cache' },
            // headers: { 'Access-Control-Allow-Origin': '*' },
            credentials: 'include'
          })

        if (response.status === 200 && response.data && response.data.token) {
          let token = response.data.token
          commit('SET_CSRF_TOKEN', token)
        }
        return await response
      } catch (e) {
        return e
      }
    }
  },
  getters: {
    userScopes(state) {
      var scopes = []

      _.forEach(state.userApps, function(item) {
        scopes.push(...item.scopes)
      })

      return scopes
    },
    authLevel: (state, getters) => scope => {
      var auth = _.find(getters.userScopes, function(item) {
        return item.name === scope
      })

      if (auth) {
        return auth.accessLevel
      }
      return 0
    }
  },
  plugins: [
    createPersistedState({
      key: 'appSettings',
      paths: [
        'drawer',
        'sales.lineSheetsPage.gridStyle',
        'sales.customerShopPage',
        'sales.allocationsPage',
        'sales.salesOrdersPage',
        'logisitcs.advanceShippingNotificationsPage'
      ]
    })
  ],
  modules: {
    production: production,
    sales: sales,
    logistics: logistics
  }
})
