export default [
  {
    path: '/finance',
    component: () =>
      import(
        /* webpackChunkName: "finance" */ '@/modules/finance/components/base/FinanceLayout'
      ),
    meta: {
      appName: 'Finance',
      auth: true,
      breadCrumb: 'Dashboard'
    },
    children: [
      {
        path: '',
        name: 'FinanceDashboard',
        component: () =>
          import(
            /* webpackChunkName: "finance" */ '@/modules/finance/views/Dashboard'
          ),
        meta: {
          pageName: 'Finance',
          appName: 'Finance',
          auth: true
        }
      },
      {
        path: 'budgets',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          pageName: 'Budgets',
          appName: 'Finance',
          auth: true,
          breadCrumb: 'Budgets'
        },
        children: [
          {
            path: '',
            name: 'BudgetList',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/views/BudgetList'
              ),
            meta: {
              pageName: 'Budgets',
              appName: 'Finance',
              auth: true
            }
          },
          {
            path: ':budgetID',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/components/base/FinanceBudgetDetailLayout'
              ),
            meta: {
              pageName: 'Budget',
              appName: 'Finance',
              auth: true,
              breadCrumb: ''
            },
            children: [
              {
                path: '',
                redirect: 'cost-breakdown',
                name: 'BudgetDetail'
                // component: () =>
                //   import(
                //     /* webpackChunkName: "finance" */ '@/modules/finance/views/BudgetDetail'
                //   ),
                // meta: {
                //   pageName: 'Budget',
                //   appName: 'Finance',
                //   auth: true
                // }
              },
              {
                path: 'description',
                name: 'BudgetDescription',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/BudgetDescription'
                  ),
                meta: {
                  pageName: 'Budget',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Details'
                }
              },
              {
                path: 'cost-breakdown',
                name: 'BudgetCosting',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/BudgetCosting'
                  ),
                meta: {
                  pageName: 'Budget',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Cost Breakdown'
                }
              },
              {
                path: 'projects',
                name: 'BudgetProjects',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/BudgetProjects'
                  ),
                meta: {
                  pageName: 'Budget',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Projects'
                }
              },
              {
                path: 'purchase-orders',
                name: 'BudgetPurchaseOrders',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/BudgetPurchaseOrders'
                  ),
                meta: {
                  pageName: 'Budget',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Purchase Orders'
                }
              },
              {
                path: 'authorization',
                name: 'BudgetAuth',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/BudgetAuth'
                  ),
                meta: {
                  pageName: 'Budget',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Authorization'
                }
              },
              {
                path: 'assets',
                name: 'BudgetAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/BudgetAssets'
                  ),
                meta: {
                  pageName: 'Budget',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Assets'
                }
              },
              {
                path: 'settings',
                name: 'BudgetSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/BudgetSettings'
                  ),
                meta: {
                  pageName: 'Budget',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'projects',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          pageName: 'Projects',
          appName: 'Finance',
          auth: true,
          breadCrumb: 'Projects'
        },
        children: [
          {
            path: '',
            name: 'ProjectList',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/views/ProjectList'
              ),
            meta: {
              pageName: 'Projects',
              appName: 'Finance',
              auth: true
            }
          },
          {
            path: ':projectID',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/components/base/FinanceProjectDetailLayout'
              ),
            meta: {
              pageName: 'Project',
              appName: 'Finance',
              auth: true,
              breadCrumb: ''
            },
            children: [
              {
                path: '',
                redirect: 'cost-breakdown',
                name: 'ProjectDetail'
                // component: () =>
                //   import(
                //     /* webpackChunkName: "finance" */ '@/modules/finance/views/ProjectDetail'
                //   ),
                // meta: {
                //   pageName: 'Project',
                //   appName: 'Finance',
                //   auth: true
                // }
              },
              {
                path: 'details',
                name: 'ProjectDetails',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/ProjectDetail'
                  ),
                meta: {
                  pageName: 'Project',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Details'
                }
              },
              {
                path: 'cost-breakdown',
                name: 'ProjectCosting',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/ProjectCosting'
                  ),
                meta: {
                  pageName: 'Project',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Cost Breakdown'
                }
              },
              {
                path: 'purchase-orders',
                name: 'ProjectPurchaseOrders',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/ProjectPurchaseOrders'
                  ),
                meta: {
                  pageName: 'Project',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Purchase Orders'
                }
              },
              {
                path: 'authorisation',
                name: 'ProjectAuth',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/ProjectAuth'
                  ),
                meta: {
                  pageName: 'Project',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Authorisation'
                }
              },
              {
                path: 'assets',
                name: 'ProjectAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/ProjectAssets'
                  ),
                meta: {
                  pageName: 'Project',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Assets'
                }
              },
              {
                path: 'settings',
                name: 'ProjectSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/ProjectSettings'
                  ),
                meta: {
                  pageName: 'Project',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'suppliers',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          pageName: 'Suppliers',
          appName: 'Finance',
          auth: true,
          breadCrumb: 'Suppliers'
        },
        children: [
          {
            path: '',
            name: 'SupplierList',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/views/SupplierList'
              ),
            meta: {
              pageName: 'Suppliers',
              appName: 'Finance',
              auth: true
            }
          },
          {
            path: ':supplierID',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/components/base/SupplierDetailLayout'
              ),
            meta: {
              pageName: 'Supplier',
              appName: 'Finance',
              auth: true,
              breadCrumb: ''
            },
            children: [
              {
                path: '',
                name: 'SupplierDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/SupplierDetail'
                  ),
                meta: {
                  pageName: 'Supplier',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Details'
                }
              },
              {
                path: 'purchase-orders',
                name: 'SupplierPurchaseOrders',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/SupplierPurchaseOrders'
                  ),
                meta: {
                  pageName: 'Supplier',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Purchase Orders'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'purchase-orders',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          pageName: 'Purchase Orders',
          appName: 'Finance',
          auth: true,
          breadCrumb: 'Purchase Orders'
        },
        children: [
          {
            path: '',
            name: 'PurchaseOrderList',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/views/PurchaseOrderList'
              ),
            meta: {
              pageName: 'Purchase Orders',
              appName: 'Finance',
              auth: true
            }
          },
          {
            path: ':purchaseOrderID',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/components/base/PurchaseOrderDetailLayout'
              ),
            meta: {
              pageName: 'Purchase Order',
              appName: 'Finance',
              auth: true,
              breadCrumb: ''
            },
            children: [
              {
                path: '',
                name: 'PurchaseOrderDetail',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/PurchaseOrderDetail'
                  ),
                meta: {
                  pageName: 'Purchase Order',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Details'
                }
              },
              {
                path: 'authorization',
                name: 'PurchaseOrderAuth',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/PurchaseOrderAuth'
                  ),
                meta: {
                  pageName: 'Purchase Order',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Authorization'
                }
              },
              {
                path: 'assets',
                name: 'PurchaseOrderAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/PurchaseOrderAssets'
                  ),
                meta: {
                  pageName: 'Purchase Order',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Assets'
                }
              },
              {
                path: 'settings',
                name: 'PurchaseOrderSettings',
                component: () =>
                  import(
                    /* webpackChunkName: "finance" */ '@/modules/finance/views/PurchaseOrderSettings'
                  ),
                meta: {
                  pageName: 'Purchase Order',
                  appName: 'Finance',
                  auth: true,
                  breadCrumb: 'Settings'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'reports',
        component: () =>
          import(
            /* webpackChunkName: "finance" */ '@/modules/finance/components/base/FinanceReportsLayout.vue'
          ),
        meta: {
          pageName: 'Reports',
          appName: 'Finance',
          auth: true,
          breadCrumb: 'Reports'
        },
        // redirect: { name: 'ProductionReportList' },
        children: [
          {
            path: '',
            name: 'FinanceReportList',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/views/FinanceReportList.vue'
              ),
            meta: {
              pageName: 'Reports',
              appName: 'Finance',
              auth: true
            }
          },
          {
            path: 'inbound-stock',
            name: 'FinanceInboundStockReport',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/views/FinanceInboundStockReport.vue'
              ),
            meta: {
              pageName: 'Reports',
              appName: 'Finance',
              auth: true,
              breadCrumb: 'Style List'
            }
          },
          // {
          //   path: 'budget-list',
          //   name: 'FinanceReportBudgetList',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "finance" */ '@/modules/finance/views/FinanceReportBudgetList.vue'
          //     ),
          //   meta: {
          //     pageName: 'Reports',
          //     appName: 'Finance',
          //     auth: true,
          //     breadCrumb: 'Style List'
          //   }
          // },
          // {
          //   path: 'project-list',
          //   name: 'FinanceReportProjectList',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "finance" */ '@/modules/finance/views/FinanceReportProjectList.vue'
          //     ),
          //   meta: {
          //     pageName: 'Reports',
          //     appName: 'Finance',
          //     auth: true,
          //     breadCrumb: 'Product List'
          //   }
          // },
          {
            path: 'manufacturing-order-payment-list',
            name: 'FinanceReportManufacturingOrderPaymentList',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/views/FinanceReportManufacturingOrderPaymentList.vue'
              ),
            meta: {
              pageName: 'Reports',
              appName: 'Finance',
              auth: true,
              breadCrumb: 'Mo Payment List'
            }
          }
        ]
      },
      {
        path: 'settings',
        component: () =>
          import(
            /* webpackChunkName: "finance" */ '@/modules/finance/components/base/FinanceSettingsLayout'
          ),
        meta: {
          pageName: 'Settings',
          appName: 'Finance',
          auth: true
        },
        children: [
          {
            path: '',
            name: 'FinanceSettingsGeneral',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/views/FinanceSettingsGeneral'
              ),
            meta: {
              pageName: 'General',
              appName: 'Finance',
              auth: true
            }
          },
          {
            path: 'financial-years',
            name: 'FinanceSettingsYears',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/views/FinanceSettingsYears'
              ),
            meta: {
              pageName: 'Financial Years',
              appName: 'Finance',
              auth: true
            }
          },
          {
            path: 'currencies',
            name: 'FinanceSettingsCurrencies',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/views/FinanceSettingsCurrencies'
              ),
            meta: {
              pageName: 'Currencies',
              appName: 'Finance',
              auth: true
            }
          },
          {
            path: 'payment-terms',
            name: 'FinanceSettingsPaymentTerms',
            component: () =>
              import(
                /* webpackChunkName: "finance" */ '@/modules/finance/views/FinanceSettingsPaymentTerms'
              ),
            meta: {
              pageName: 'Payment Terms',
              appName: 'Finance',
              auth: true
            }
          }
        ]
      }
    ]
  }
]
