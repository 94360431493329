export default {
  namespaced: true,
  state: {
    lineSheetsPage: {
      page: 1,
      pageSize: 15,
      season: null,
      owner: null,
      search: null,
      gridStyle: 0
    },
    customerShopPage: {
      season: null,
      articleGroup: null,
      gender: null,
      search: null
    },
    allocationsPage: {
      page: 1,
      pageSize: 15
    },
    salesOrdersPage: {
      page: 1,
      pageSize: 15,
      search: null,
      state: null,
      type: null,
      customer: null
    }
  },
  mutations: {
    SET_LINE_SHEETS_PAGE(state, item) {
      state.lineSheetsPage.page = item
    },

    SET_LINE_SHEETS_PAGE_SIZE(state, item) {
      state.lineSheetsPage.pageSize = item
    },

    SET_LINE_SHEETS_SEASON(state, item) {
      state.lineSheetsPage.season = item
    },

    SET_LINE_SHEETS_OWNER(state, item) {
      state.lineSheetsPage.owner = item
    },

    SET_LINE_SHEETS_SEARCH(state, item) {
      state.lineSheetsPage.search = item
    },

    SET_LINE_SHEETS_GRID(state, item) {
      state.lineSheetsPage.gridStyle = item
    },

    RESET_LINE_SHEETS_PAGE(state) {
      state.lineSheetsPage = {
        page: 1,
        pageSize: 15,
        season: null,
        owner: null,
        search: null,
        gridStyle: 0
      }
    },

    // customer product page
    SET_CUSTOMER_PRODUCT_SEARCH(state, item) {
      state.customerShopPage.search = item
    },
    SET_CUSTOMER_PRODUCT_SEASON(state, item) {
      state.customerShopPage.season = item
    },
    SET_CUSTOMER_PRODUCT_ARTICLE_GROUP(state, item) {
      state.customerShopPage.articleGroup = item
    },
    SET_CUSTOMER_PRODUCT_GENDER(state, item) {
      state.customerShopPage.gender = item
    },

    // allocations page
    SET_ALLOCATIONS_PAGE(state, item) {
      state.allocationsPage.page = item
    },

    SET_ALLOCATIONS_PAGE_SIZE(state, item) {
      state.allocationsPage.pageSize = item
    },

    // sales orders page
    SET_SALES_ORDERS_PAGE(state, item) {
      state.salesOrdersPage.page = item
    },

    SET_SALES_ORDERS_PAGE_SIZE(state, item) {
      state.salesOrdersPage.pageSize = item
    },

    SET_SALES_ORDERS_SEARCH(state, item) {
      state.salesOrdersPage.search = item
    },

    SET_SALES_ORDERS_STATE(state, item) {
      state.salesOrdersPage.state = item
    },

    SET_SALES_ORDERS_TYPE(state, item) {
      state.salesOrdersPage.type = item
    },

    SET_SALES_ORDERS_CUSTOMER(state, item) {
      state.salesOrdersPage.customer = item
    }
  },
  actions: {},
  getters: {}
}
