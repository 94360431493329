import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login.vue'
import ActivateAccount from '@/views/ActivateAccount.vue'
import ResetPassword from '@/views/ResetPassword.vue'

// Modules
import accountRoutes from '@/modules/accounts/accountsRouter'
import adminRoutes from '@/modules/admin/adminRouter'
import financeRouter from '@/modules/finance/financeRouter'
import productionRouter from '@/modules/production/productionRouter'
import salesRouter from '@/modules/sales/salesRouter'
import ecommRouter from '@/modules/ecomm/ecommRouter'
import logisticsRouter from '@/modules/logistics/logisticsRouter'
import reviewRouter from '@/modules/reviews/reviewRouter'

Vue.use(Router)

const baseRoutes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageName: 'Dashboard',
      auth: true,
      appName: 'Dashboard'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (Vue.$cookies.isKey('hpa')) {
        next('/')
      }
      next()
    }
  },
  {
    path: '/activate/:token',
    name: 'ActivateAccount',
    component: ActivateAccount
  },
  {
    path: '/password/reset/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/not-allowed',
    component: () => import('@/views/NotAllowed.vue')
  },
  {
    path: '*',
    component: () => import('@/views/PageNotFound.vue')
  }
]

const routes = baseRoutes.concat(
  accountRoutes,
  adminRoutes,
  financeRouter,
  productionRouter,
  salesRouter,
  ecommRouter,
  logisticsRouter,
  reviewRouter
)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  to.matched.some(routeRecord => {
    const auth = routeRecord.meta.auth

    if (auth) {
      var authCookie = document.cookie
        .split(';')
        .filter(item => item.trim().startsWith('hpa='))
      if (!document.cookie || authCookie.length === 0) {
        next('/login?redirect=' + to.fullPath)
      }
      next()
    } else {
      next()
    }
  })
})

export default router
