<template>
  <v-container fill-height style="margin-top: 100px">
    <v-snackbar v-model="passResetSuccess" :timeout="10000">
      {{ snackbarMsg }}
      <v-btn color="blue" text @click="passResetSuccess = false">
        close
      </v-btn>
    </v-snackbar>
    <v-layout column>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <div class="login__container">
            <v-row>
              <v-col cols="12">
                <v-row text-center justify="center">
                  <!-- <v-img src="/logo.png" aspect-ratio="1.7" contain width="200px" height="100"></v-img> -->
                  <v-col cols="12">
                    <v-row justify="center">
                      <v-img src="/logo.png" contain max-width="200"></v-img>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="showVerrifyEmailAlert" cols="12">
                <v-row justify="center">
                  <v-col cols="12">
                    <v-alert type="info">
                      <v-row align="center">
                        <v-col cols="12">
                          <h2 class="mb-3">Verrify Email</h2>
                          <p>
                            We have send you an email invite with instructions.
                            If you have not received an email, please check your
                            spam box or request a new one.
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <v-row justify="center">
                            <v-btn
                              :to="{
                                name: 'ActivateAccount',
                                params: { token: 'resend-link' }
                              }"
                              color="primary"
                              >Resend Link</v-btn
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-else cols="12">
                <v-row v-if="!resetPassword">
                  <v-col cols="12">
                    <v-row justify="center">
                      <v-col xs="12" sm="12" md="10" lg="8" xl="6">
                        <v-alert
                          v-model="showError"
                          type="error"
                          class="mb-3"
                          >{{ errorMsg }}</v-alert
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row justify="center">
                      <v-col xs="12" sm="12" md="10" lg="8" xl="6">
                        <v-card class="px-8 py-5" flat>
                          <v-col cols="12" class="text-center">
                            <h2 class="primary--text mb-0">Welcome back</h2>
                            <v-alert class="mb-0"
                              >Please login to continue</v-alert
                            >
                          </v-col>

                          <v-form
                            ref="loginForm"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent="login"
                          >
                            <v-text-field
                              v-model="user.email"
                              :rules="emailRules"
                              type="email"
                              label="E-mail"
                              outlined
                              autofocus
                              color="primary lighten-3"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="user.password"
                              :append-icon="
                                show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                              "
                              :rules="[v => !!v || 'Password is required']"
                              outlined
                              label="Password"
                              color="primary lighten-3"
                              :type="show ? 'text' : 'password'"
                              autocomplete="off"
                              required
                              @click:append="show = !show"
                            ></v-text-field>
                            <v-checkbox
                              label="Remember me"
                              class="mt-0"
                            ></v-checkbox>

                            <v-btn type="submit" block large color="primary">
                              <v-icon left>mdi-lock-outline</v-icon>Sign in to
                              your account
                            </v-btn>
                            <v-row>
                              <v-col cols="12">
                                <v-btn
                                  text
                                  small
                                  color="primary"
                                  @click="resetPassword = true"
                                  >Forgot Password</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    <v-row justify="center">
                      <v-col xs="12" sm="12" md="10" lg="8" xl="6">
                        <v-alert
                          v-model="showError"
                          type="error"
                          class="mb-3"
                          >{{ errorMsg }}</v-alert
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row justify="center">
                      <v-col xs="12" sm="12" md="10" lg="8" xl="6">
                        <v-card class="px-8 py-5" flat>
                          <v-row>
                            <v-col cols="12">
                              <h2 class="primary--text mb-0 text-center">
                                Forgot Password
                              </h2>
                            </v-col>
                            <v-col cols="12">
                              <p class="text-center">
                                Request a password reset. You will receive an
                                email with further instructions.
                              </p>
                            </v-col>

                            <v-col cols="12" class="pb-0">
                              <v-form
                                ref="passwordResetForm"
                                v-model="validPasswordResetForm"
                              >
                                <v-text-field
                                  v-model="passwordResetEmail"
                                  label="Email"
                                  type="email"
                                  placeholder="Email used to signup"
                                  outlined
                                  autofocus
                                  :rules="emailRules"
                                ></v-text-field>
                              </v-form>
                            </v-col>
                            <v-col cols="12" class="pt-0">
                              <v-btn
                                :disabled="!validPasswordResetForm"
                                color="primary"
                                large
                                block
                                @click="requestPasswordReset"
                                >Request Password Reset</v-btn
                              >
                            </v-col>

                            <v-col cols="12">
                              <v-btn
                                text
                                small
                                color="primary"
                                @click="resetPassword = false"
                                >back to login</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-layout align-end row>
        <v-flex xs12>
          <v-footer height="auto" color="transparent">
            <v-layout align-center justify-center column>
              <v-flex text-xs-center xs12>
                <a href="https://www.clebbr.com/privacy" class="copy-link"
                  >Privacy Policy&nbsp;</a
                >
                |
                <a href="https://www.clebbr.com" class="copy-link"
                  >&nbsp;&copy; Clebbr Ltd</a
                >
              </v-flex>
            </v-layout>
          </v-footer>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
import LOGIN from '@/graphql/Login.gql'
import GET_ME from '@/graphql/GetMe.gql'
import USER_APP_LIST from '@/graphql/UserApps.gql'
import RESET_PASSWORD from '@/graphql/PasswordReset.gql'

export default {
  data() {
    return {
      user: {
        email: '',
        password: null
      },
      show: false,
      valid: true,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => 'The password is required'
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      errorMsg: 'Invalid username or password',
      showError: false,
      showVerrifyEmailAlert: false,
      validPasswordResetForm: true,
      resetPassword: false,
      passwordResetEmail: null,
      passResetSuccess: false,
      snackbarMsg: ''
    }
  },
  created() {
    this.setTheme()
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        return true
      }
    },
    requestPasswordReset() {
      this.passResetSuccess = false
      if (this.$refs.passwordResetForm.validate()) {
        this.$apollo
          .mutate({
            mutation: RESET_PASSWORD,
            variables: {
              input: {
                email: this.passwordResetEmail
              }
            }
          })
          .then(() => {
            this.passwordResetEmail = null
            this.$refs.passwordResetForm.reset()
            this.resetPassword = false

            this.snackbarMsg =
              'Request Send. Check your email for further instructions.'
            this.passResetSuccess = true
          })
          .catch(() => {
            this.passwordResetEmail = null
            this.$refs.passwordResetForm.reset()
            this.resetPassword = false

            this.snackbarMsg =
              'Request Send. Check your email for further instructions.'
            this.passResetSuccess = true
          })
      }
    },
    login() {
      this.showError = false
      if (this.$refs.loginForm.validate()) {
        const user = this.user
        var that = this
        this.$apollo
          .mutate({
            mutation: LOGIN,
            // Parameters
            variables: {
              input: user
            }
          })
          .then(res => {
            if (
              res.data &&
              res.data.createSession &&
              res.data.createSession.accessToken
            ) {
              that.$apollo
                .query({
                  query: GET_ME,
                  fetchPolicy: 'network-only'
                })
                .then(result => {
                  if (!result) {
                    that.user = user
                  } else {
                    that.$store.commit('SET_USER', result.data.getMe)
                    var userID = result.data.getMe.id

                    that.$apollo
                      .query({
                        query: USER_APP_LIST,
                        fetchPolicy: 'network-only',
                        variables: {
                          userID: userID
                        }
                      })
                      .then(res => {
                        this.$store.commit('SET_USER_APPS', res.data.userApps)
                        // Redirect
                        let redirect = that.$route.query.redirect
                        if (redirect && redirect !== '') {
                          // Get the user and set in store
                          that.$router.push(redirect).catch(() => {})
                        } else {
                          that.$router
                            .push({ name: 'Dashboard' })
                            .catch(() => {})
                        }
                      })
                      .catch(() => {})
                  }
                })
                .catch(() => {
                  // Error
                  that.errorMsg = 'Something went wrong. Please try again'
                  that.showError = true
                  // We restore the initial user input
                  that.user = user
                })
            }
            // Not authenticated
            // that.showError = true
            // Get the user and set in store
            // var cookieVal = window.$cookies.get('hpa')
            // var payload = cookieVal.split('.')[1]
            // var dec = JSON.parse(window.atob(payload))
            // var sub = dec.sub
          })
          .catch(error => {
            let msg = error.message
            switch (msg) {
              case 'GraphQL error: Unauthorized':
                that.errorMsg = 'Wrong Email or Password'
                that.showError = true
                break
              case 'GraphQL error: ValidateEmail':
                // that.errorMsg =
                //   'Please verrify your email address. If you have not received an email, please check your spambox or request a new one.'
                that.showVerrifyEmailAlert = true
                break
              case 'GraphQL error: Suspended':
                that.errorMsg = 'Your account has been suspended!'
                that.showError = true
                break
              default:
                that.errorMsg = 'Something went wrong. Please try again'
                that.showError = true
                break
            }
            // We restore the initial user input
            that.user = user
          })
      }
    },
    setTheme() {
      this.$vuetify.theme.themes.light.primary = '#1976D2'
      this.$vuetify.theme.themes.light.secondary = '#424242'
      this.$vuetify.theme.themes.light.accent = '#82B1FF'
      this.$vuetify.theme.themes.light.error = '#FF5252'
      this.$vuetify.theme.themes.light.info = '#2196F3'
      this.$vuetify.theme.themes.light.success = '#4CAF50'
      this.$vuetify.theme.themes.light.warning = '#FFC107'
    }
  }
}
</script>

<style lang="scss">
.login__container {
  height: 100%;
}

// .login-box {
//   width: 400px;
//   border-radius: 5px;
// }

// .v-input__slot {
//   // border-color: var(--v-primary-lighten3);
//   border-radius: 5px !important;
// }

.copy-link {
  text-decoration: none;
  color: var(--v-secondary-base) !important;
  font-size: small;

  &:hover {
    text-decoration: underline;
    // color: var(--v-info-darken1);
  }
}
</style>
