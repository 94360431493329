export default {
  namespaced: true,
  state: {
    advanceShippingNotificationsPage: {
      page: 1,
      pageSize: 15,
      manufacturingOrderID: null,
      state: null,
      search: null
    }
  },
  mutations: {
    SET_ASN_PAGE(state, item) {
      state.advanceShippingNotificationsPage.page = item
    },

    SET_ASN_PAGE_SIZE(state, item) {
      state.advanceShippingNotificationsPage.pageSize = item
    },

    SET_ASN_MANUFACTURING_ORDER_ID(state, item) {
      state.advanceShippingNotificationsPage.manufacturingOrderID = item
    },

    SET_ASN_STATE(state, item) {
      state.advanceShippingNotificationsPage.state = item
    },

    SET_ASN_SEARCH(state, item) {
      state.advanceShippingNotificationsPage.search = item
    },

    RESET_ASN(state) {
      state.advanceShippingNotificationsPage = {
        page: 1,
        pageSize: 15,
        manufacturingOrderID: null,
        state: null,
        search: null
      }
    }
  },
  actions: {},
  getters: {}
}
