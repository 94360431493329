export default [
  {
    path: '/ecomm',
    component: () =>
      import(
        /* webpackChunkName: "" */ '@/modules/ecomm/components/base/EcommLayout'
      ),
    meta: {
      auth: true,
      breadCrumb: 'Dashboard'
    },
    // beforeEnter: (to, from, next) => {
    //   apolloClient
    //     .query({
    //       query: APP_LIST
    //     })
    //     .then(result => {
    //       let app = _.find(result.data.apps, function (item) {
    //         return item.name === 'Ecomm'
    //       })

    //       store.commit('setCurrentApp', app)
    //       return next()
    //     })

    //   return next()
    // },
    children: [
      {
        path: '',
        // name: 'EcommDashboard',
        // component: () =>
        //   import(
        //     /* webpackChunkName: "ecomm" */ '@/modules/ecomm/views/Dashboard.vue'
        //   ),
        // meta: {
        //   pageName: 'Dashboard',
        //   appName: 'Ecomm',
        //   auth: true
        // },
        redirect: {
          name: 'EcommOrderList'
        }
      },
      {
        path: 'orders',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          auth: true,
          breadCrumb: 'Ecomm Orders'
        },
        children: [
          {
            path: '',
            name: 'EcommOrderList',
            component: () =>
              import(
                /* webpackChunkName: "ecomm" */ '@/modules/ecomm/views/EcommOrderList.vue'
              ),
            meta: {
              pageName: 'Ecomm Orders',
              appName: 'Ecomm',
              auth: true
            }
          },
          {
            path: ':ecommOrderID',
            name: 'EcommOrderDetails',
            component: () =>
              import(
                /* webpackChunkName: "ecomm" */ '@/modules/ecomm/views/EcommOrderDetail.vue'
              ),
            meta: {
              pageName: 'Ecomm Orders',
              appName: 'Ecomm',
              auth: true,
              breadCrumb: 'Details'
            }
          }
        ]
      },
      {
        path: 'tags',
        name: 'EcommTagList',
        component: () =>
          import(
            /* webpackChunkName: "ecomm" */ '@/modules/ecomm/views/EcommTagList.vue'
          ),
        meta: {
          pageName: 'Ecomm Orders',
          appName: 'Ecomm',
          auth: true
        }
      },
    ]
  }
]
