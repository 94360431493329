export default [
  {
    path: '/reviews',
    component: () =>
      import(
        /* webpackChunkName: "reviews" */ '@/modules/reviews/components/base/ReviewLayout'
      ),
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'ReviewList',
        component: () =>
          import(
            /* webpackChunkName: "reviews" */ '@/modules/reviews/views/ReviewList.vue'
          ),
        meta: {
          pageName: 'Reviews',
          appName: 'Reviews',
          auth: true
        }
      }
    ]
  }
]
