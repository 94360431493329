export default {
  namespaced: true,
  state: {
    productsPage: {
      page: 1,
      pageSize: 15,
      season: null,
      designer: null,
      producer: null,
      search: null
    }
  },
  mutations: {
    SET_PAGE(state, item) {
      state.productsPage.page = item
    },

    SET_PAGE_SIZE(state, item) {
      state.productsPage.pageSize = item
    },

    SET_SEASON(state, item) {
      state.productsPage.season = item
    },

    SET_DESIGNER(state, item) {
      state.productsPage.designer = item
    },

    SET_PRODUCER(state, item) {
      state.productsPage.producer = item
    },

    SET_SEARCH(state, item) {
      state.productsPage.search = item
    },

    RESET_PRODUCT_PAGE(state) {
      state.productsPage = {
        page: 1,
        season: null,
        designer: null,
        developer: null,
        search: null
      }
    }
  },
  actions: {},
  getters: {}
}
